import React from 'react'
import PropTypes from 'prop-types'

// Styles
import {
  ButtonContent,
  ButtonMain,
  ButtonIcon,
  ButtonText,
  AnimateIcon,
} from './index.style'

/**
 *
 * @prop variant
 * Defines the visual style
 * @prop href
 * If defined this renders the component as an anchor element with an href attribute <a href={href} />
 * @prop inline
 * If true this renders the component as a div. This is necessary when wrapping in a PageTransitionLink component
 * @prop label
 * If defined this adds an aria-label to the component. Necessary for icon only buttons.
 */

const Button = ({
  variant = 'primary',
  disabled,
  href,
  inline,
  label,
  iconLeft,
  iconRight,
  onClick,
  children,
}) => (
  <ButtonMain
    as={href ? 'a' : inline ? 'div' : 'button'}
    href={href}
    variant={variant}
    disabled={disabled}
    aria-label={label}
    onClick={onClick}
  >
    <ButtonContent>
      {iconLeft && <ButtonIcon left>{iconLeft}</ButtonIcon>}
      {children && <ButtonText>{children}</ButtonText>}
      {iconRight && (
        <ButtonIcon right>
          <AnimateIcon>{iconRight}</AnimateIcon>{' '}
          <AnimateIcon>{iconRight}</AnimateIcon>
        </ButtonIcon>
      )}
    </ButtonContent>
  </ButtonMain>
)

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  href: PropTypes.string,
  label: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default Button
