import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import FontFaceObserver from 'fontfaceobserver'

// Components
import {
  useStore,
  updatePathname,
  updatePopState,
  loadFonts,
} from '../../Store'
import TransitionMask from '../PageTransitionMask'
import Gridlines from '../Gridlines'
import Header from '../Header'
import Footer from '../Footer'
import FooterForm from '../FooterForm'

// Fonts
import FoundersLightEot from '../../../static/fonts/founders-grotesk/founders-grotesk-light.eot'
import FoundersLight from '../../../static/fonts/founders-grotesk/founders-grotesk-light.woff'
import FoundersLightWoff2 from '../../../static/fonts/founders-grotesk/founders-grotesk-light.woff2'
import FoundersRegEot from '../../../static/fonts/founders-grotesk/founders-grotesk-regular.eot'
import FoundersReg from '../../../static/fonts/founders-grotesk/founders-grotesk-regular.woff'
import FoundersRegWoff2 from '../../../static/fonts/founders-grotesk/founders-grotesk-regular.woff2'

import SuisseEot from '../../../static/fonts/suisse/SuisseWorks-Regular-WebS.eot'
import SuisseWoff from '../../../static/fonts/suisse/SuisseWorks-Regular-WebS.woff'
import SuisseWoff2 from '../../../static/fonts/suisse/SuisseWorks-Regular-WebS.woff2'

// Styles
import { GlobalStyles } from '../../styles/GlobalStyles.style'
import { font } from '../../styles/vars/font.style'

const Layout = ({ children, location }) => {
  const [, dispatch] = useStore()

  const [keyMode, setKeyMode] = useState(false)

  const handleBrowserNavigationInteraction = useCallback(
    e => {
      updatePathname(dispatch, window.location.pathname)
      updatePopState(dispatch)
    },
    [dispatch]
  )

  const setViewportHeight = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  const handleWebfontLoad = useCallback(() => {
    const fontA = new FontFaceObserver(font.primary.family)

    fontA.load().then(
      () => {
        loadFonts(dispatch)
      },
      () => {
        console.log('Font is not available')
      }
    )
  }, [dispatch])

  useEffect(() => {
    setViewportHeight()
    handleWebfontLoad()

    window.addEventListener('resize', setViewportHeight)
    window.addEventListener('popstate', handleBrowserNavigationInteraction)

    return () => {
      window.removeEventListener('resize', setViewportHeight)
      window.removeEventListener('popstate', handleBrowserNavigationInteraction)
    }
  }, [dispatch, handleWebfontLoad, handleBrowserNavigationInteraction])

  const setMouseMode = useCallback(() => {
    if (!keyMode) return
    setKeyMode(false)
    document.body.classList.remove('keyboard-in-use')
  }, [keyMode])

  const setKeyboardMode = useCallback(() => {
    if (keyMode) return
    setKeyMode(true)
    document.body.classList.add('keyboard-in-use')
  }, [keyMode])

  useEffect(() => {
    document.body.addEventListener('mousedown', setMouseMode)
    document.body.addEventListener('keydown', setKeyboardMode)

    return () => {
      document.body.removeEventListener('mousedown', setMouseMode)
      document.body.removeEventListener('keydown', setKeyboardMode)
    }
  }, [setMouseMode, setKeyboardMode])

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <html lang="en" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <style>{`
          @font-face {
            font-family: 'Founders Grotesk';
            src: url(${FoundersLightEot}) format('eot'),
              url(${FoundersLight}) format('woff'),
              url(${FoundersLightWoff2}) format('woff2');
            font-weight: ${font.primary.weight.light};
          }
          @font-face {
            font-family: 'Founders Grotesk';
            src: url(${FoundersRegEot}) format('eot'),
              url(${FoundersReg}) format('woff'),
              url(${FoundersRegWoff2}) format('woff2');
            font-weight: ${font.primary.weight.regular};
          }
          @font-face {
            font-family: 'Suisse Works';
            src: url(${SuisseEot}) format('eot'),
              url(${SuisseWoff}) format('woff'),
              url(${SuisseWoff2}) format('woff2');
            font-weight: ${font.secondary.weight.regular};
          }
        `}</style>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js" />

        <script id="oada_ma_toolbar_script">{`
          var oada_ma_license_key="hO9c2KYRI0ZmA5wwcrT1WrZVi1Sk1B";var oada_ma_license_url="https://api.maxaccess.io/scripts/toolbar/hO9c2KYRI0ZmA5wwcrT1WrZVi1Sk1B";(function(s,o,g){a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.src=g;a.setAttribute("async","");a.setAttribute("type","text/javascript");a.setAttribute("crossorigin","anonymous");m.parentNode.insertBefore(a,m)})(document,"script",oada_ma_license_url+oada_ma_license_key);
        `}</script>
      </Helmet>

      <Gridlines show={false} />

      <TransitionMask />
      <div id="scroll-container" data-scroll-container>
        <Header pathname={location.pathname} />

        {children}

        <FooterForm />
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
