import styled from 'styled-components'
import { setVh } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { easings } from '../../styles/vars/easings.style'
import { clamp } from '../../styles/utils/conversion.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { zIndex } from '../../styles/vars/zIndex.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'

export const HeaderWrap = styled.header`
  opacity: ${({ inView }) => (inView ? `1` : `0`)};
  transition: opacity 0.6s ${easings.inOut.default} 1s;
  z-index: ${zIndex.header};
`

export const HeaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.header};

  ${mq.desk} {
    position: ${({ scrolled }) => (scrolled === true ? `fixed` : `absolute`)};

    ${({ animateIn, scrolled }) => {
      if (scrolled === true) {
        if (animateIn === false) {
          return `transform: translateY(-10rem);`
        } else {
          return `
        background-color: ${colors.cream};
        border-bottom: .1rem solid #CBCBC2;
        transform: translateY(0rem);
        transition: transform 1s ${easings.inOut.default};`
        }
      } else if (scrolled === false) {
        return `
      transform: translateY(0rem);`
      }
    }}
  }
`

export const HeaderMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${clamp('padding-top', 30, 24, breakpoints.mobile, breakpoints.tabletL)}

  ${mq.tabletL} {
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;
  }
`

export const HeaderLogo = styled.a`
  display: none;
  position: relative;
  z-index: ${zIndex.mobileNavButtons};

  ${mq.desk} {
    display: inline-block;

    svg {
      &:first-of-type {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        transition: opacity 0.5s ${easings.inOut.default};
        ${({ scrolled }) => {
          if (scrolled === true) {
            return `opacity: 0;`
          } else {
            return `opacity: 1; transition-delay: .5s;`
          }
        }}
      }
    }

    svg {
      &:last-of-type {
        height: 4.9rem;
        width: 4.5rem;
        transition: opacity 0.5s ${easings.inOut.default};

        ${({ scrolled }) => {
          if (scrolled === true) {
            return `opacity: 1;`
          } else {
            return `opacity: 0;`
          }
        }}
      }
    }
  }
`
export const HeaderLogoMainMob = styled.div`
  position: relative;
  width: 100%;
  z-index: ${zIndex.mobileNavButtons};

  svg {
    width: 100%;
  }

  ${mq.desk} {
    display: none;
  }
`

export const HeaderNav = styled.nav`
  display: none;

  ${mq.desk} {
    display: block;

    ${({ hide }) =>
      hide === true
        ? `
    opacity: 0; pointer-events: none;`
        : `
    opacity: 1; pointer-events: all;`}
  }
`

export const HeaderMenu = styled.ul`
  display: none;

  ${mq.desk} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    text-align: right;
  }
`

export const HeaderMenuItem = styled.li`
  color: ${({ active }) => (active ? `${colors.green}` : `${colors.black}`)};
  margin-right: 3rem;
  padding-left: 1.5rem;
  position: relative;

  &:hover {
    color: ${colors.green};
  }

  &:before {
    background-color: ${({ active }) =>
      active ? `${colors.green}` : `${colors.black}`};
    border-radius: 50%;
    content: '';
    height: 0.5rem;
    opacity: ${({ active }) => (active ? 1 : 0)};
    position: absolute;
    left: 0rem;
    top: 50%;
    transform: translateY(-50%)
      ${({ active, scrollDirection }) =>
        active
          ? `translateX(0)`
          : scrollDirection === 'down'
          ? `translateX(-100%)`
          : `translateX(100%)`};

    transition: opacity 0.25s ${easings.inOut.default},
      transform 0.35s ${easings.inOut.default};
    width: 0.5rem;
  }

  &:last-of-type {
    margin-right: 0rem;
  }

  ${PageTransitionLinkMain} {
    display: inline-flex;
  }
`

export const HeaderLinkItem = styled.a`
  display: inline-block;
  text-decoration: none;
`

export const MobileNav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: ${props => (props.open ? 'translateX(0)' : 'translateX(-100%)')};
  width: 100%;
  ${setVh('height', 100)}
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.mobileNav};
  pointer-events: ${props => (props.open ? undefined : 'none')};
  text-align: center;
  background-color: ${colors.light};
  transition: transform 0.2s ${easings.inOut.default};

  ${mq.desk} {
    display: none;
  }
`

export const MobileNavMain = styled.nav``

export const MobileMenu = styled.ul`
  list-style: none;
`

export const MobileMenuItem = styled.li``
