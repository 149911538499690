import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { clamp } from '../../styles/utils/conversion.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { easings } from '../../styles/vars/easings.style'

export const FooterContainer = styled.footer`
  background-color: ${colors.green};
  color: ${colors.cream};
  position: relative;

  &:before {
    background-color: ${colors.cream};
    content: '';
    left: 0;
    right: 0;
    top: 0;
    height: 0.1rem;
    opacity: 0.3;
    position: absolute;
  }

  a {
    text-decoration: none;
    position: relative;

    &:before {
      background-color: ${colors.cream};
      bottom: -0.2rem;
      content: '';
      height: 0.1rem;
      left: 0;
      position: absolute;
      right: 0;
      transform: scaleX(0);
      transform-origin: right center;
      transition: transform 0.25s ${easings.inOut.default};
    }

    ${mq.desk} {
      &:hover {
        &:before {
          transform: scaleX(1);
          transform-origin: left center;
        }
      }
    }
  }
`

export const FooterMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${clamp('padding-top', 12, 24, breakpoints.mobile, breakpoints.tabletL)}
`

export const FooterLogo = styled.div`
  margin-bottom: 3rem;
  position: relative;
  text-align: center;

  ${mq.tabletL} {
    text-align: left;
  }

  svg {
    display: inline-block;
    ${clamp('height', 41, 65)}
    ${clamp('width', 120, 190)}
  }
`
export const FooterAddress = styled.div`
  text-align: center;
  margin-bottom: 3rem;

  ${mq.tabletL} {
    text-align: left;
  }
  ${mq.desk} {
    transform: translateX(20%);
  }
`

export const FooterNav = styled.nav`
  text-align: center;

  ${mq.tabletL} {
    text-align: left;
  }
`

export const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
`

export const FooterMenuItem = styled.li``

export const FooterLogos = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  margin-bottom: 5rem;
  margin-top: 5rem;

  ${mq.tabletL} {
    flex-direction: column;
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
`
export const FooterLogoItem = styled.li`
  &:not(:last-of-type) {
    margin-right: 3rem;

    ${mq.tabletL} {
      ${clamp('margin-bottom', 12, 28, breakpoints.tabletL, breakpoints.deskL)}
      margin-right: 0rem;
    }
  }
`

export const FooterImage = styled.div`
  display: inline-block;
`

export const ArrowWrap = styled.div`
  overflow: hidden;
  height: 1.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 1.5rem;

  &:first-of-type {
    svg {
      transform: translateX(-50%) translateY(-50%) rotate(180deg);
      transition-delay: 0.2s;
    }
  }
  &:last-of-type {
    svg {
      transform: translateX(-50%) translateY(-50%) rotate(180deg)
        translateY(-100%);
    }
  }
`

export const ToTopButton = styled.button`
  background: transparent;
  border: 0.1rem solid ${colors.cream};
  border-radius: 50%;
  cursor: pointer;
  display: none;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(180deg);
  }

  ${mq.tabletL} {
    display: inline-block;
    ${clamp('height', 60, 80)}
    ${clamp('width', 60, 80)}

    svg {
      transition: transform 0.5s ${easings.inOut.default};
    }

    &:hover {
      ${ArrowWrap} {
        &:first-of-type {
          svg {
            transform: translateX(-50%) translateY(-50%) rotate(180deg)
              translateY(100%);
          }
        }
        &:last-of-type {
          svg {
            transform: translateX(-50%) translateY(-50%) rotate(180deg);
            transition-delay: 0.2s;
          }
        }
      }
    }
  }
`

export const FooterToTop = styled.div`
  text-align: right;
  position: relative;
`

export const FooterCopyright = styled.div`
  text-align: center;

  ${mq.tabletL} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${clamp('margin-top', 100, 220)}
  }
`
