import { createGlobalStyle } from 'styled-components'
import { reset } from './partials/reset.style'
import { typography } from './partials/typography.style'
import { locomotiveScrollStyles } from './partials/locomotiveScroll.style'
import { gatsbyTransitionLinkStyles } from './partials/gatsbyTransitionLink.style'
import { colors } from './vars/colors.style'
import { zIndex } from './vars/zIndex.style'

export const GlobalStyles = createGlobalStyle`
  ${reset}
  ${typography}
  ${locomotiveScrollStyles}
  ${gatsbyTransitionLinkStyles}

  :root {
    --reach-tabs: 1; // This disables the Reach UI warning about not including matching CSS files https://reach.tech/styling/
  }

  * {
    -webkit-marquee-increment: 0vw; // This ensures that clamp values are updated on window resize in Safari
  }

  body {
    background-color: ${colors.cream};

    &.disable-scroll {
      overflow: hidden;
    }
  }

  #portal {
    position: relative;
    z-index: ${zIndex.portal};
  }
`
