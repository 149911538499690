import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'

// Components
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Spacer from '../Spacer'

// Animation
import AnimateSlideIn from '../animation/AnimateSlideIn'

//Styles
import {
  FooterContainer,
  FooterMain,
  FooterNav,
  FooterMenu,
  FooterMenuItem,
  FooterLogo,
  FooterAddress,
  ToTopButton,
  FooterImage,
  FooterLogos,
  FooterLogoItem,
  FooterCopyright,
  FooterToTop,
  ArrowWrap,
} from './index.style'
import { colors } from '../../styles/vars/colors.style'
import { Copyright, TextBodySmallest } from '../TextStyles'

// Svgs
import Arrow from '../svgs/Arrow'
import LogoDesk from '../svgs/Logo'

// Images
import Classic from './assets/marc-rose-monogram.png'

gsap.registerPlugin(ScrollToPlugin)

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      contentfulGlobalSettings {
        address {
          address
        }
        contactEmail
        telephoneNumber
        faxNumber
      }
    }
  `)

  return (
    <FooterContainer>
      <Container>
        <Spacer size={[60, 80]} />
        <FooterMain>
          <Grid>
            <GridItem tiny={8} tinyStart={3} tabletL={2} tabletLOrder={1}>
              <FooterLogo>
                <AnimateSlideIn>
                  <LogoDesk fill={colors.cream} idSuffix="footer" />
                </AnimateSlideIn>
              </FooterLogo>
            </GridItem>
            <GridItem
              tiny={8}
              tinyStart={3}
              tabletP={4}
              tabletPStart={5}
              tabletL={3}
              tabletLStart={5}
              tabletLOrder={2}
              desk={2}
              deskStart={3}
            >
              <FooterAddress>
                <TextBodySmallest>
                  {data.contentfulGlobalSettings.address.address}
                </TextBodySmallest>
              </FooterAddress>
            </GridItem>
            <GridItem
              tiny={8}
              tinyStart={3}
              tabletL={3}
              tabletLStart={5}
              tabletLOrder={5}
              desk={3}
              deskStart={6}
              deskOrder={3}
            >
              <FooterNav>
                <FooterMenu>
                  {data.contentfulGlobalSettings.contactEmail && (
                    <FooterMenuItem>
                      <TextBodySmallest>
                        E:{' '}
                        <a
                          href={`mailto:${data.contentfulGlobalSettings.contactEmail}`}
                        >
                          {data.contentfulGlobalSettings.contactEmail}
                        </a>
                      </TextBodySmallest>
                    </FooterMenuItem>
                  )}
                  {data.contentfulGlobalSettings.telephoneNumber && (
                    <FooterMenuItem>
                      <TextBodySmallest>
                        T:{' '}
                        <a
                          href={`tel:${data.contentfulGlobalSettings.telephoneNumber}`}
                        >
                          {data.contentfulGlobalSettings.telephoneNumber}
                        </a>
                      </TextBodySmallest>
                    </FooterMenuItem>
                  )}
                  {data.contentfulGlobalSettings.faxNumber && (
                    <FooterMenuItem>
                      <TextBodySmallest>
                        F:{' '}
                        <a
                          href={`mailto:${data.contentfulGlobalSettings.faxNumber}`}
                        >
                          {data.contentfulGlobalSettings.faxNumber}
                        </a>
                      </TextBodySmallest>
                    </FooterMenuItem>
                  )}
                </FooterMenu>
              </FooterNav>
            </GridItem>

            <GridItem
              tiny={8}
              tinyStart={3}
              tabletP={6}
              tabletPStart={4}
              tabletL={3}
              tabletLStart={9}
              tabletLOrder={4}
              desk={2}
              deskStart={10}
              deskOrder={4}
              deskL={1}
              deskLStart={11}
            >
              <FooterLogos>
                <FooterLogoItem>
                  <FooterImage>
                    <a
                      href={'https://marcandrosehospitality.com/'}
                      aria-label={`Marc & Rose Hospitality`}
                    >
                      <img
                        src={Classic}
                        height={32}
                        width={125}
                        alt="Marc & Rose Hospitality Logo"
                      />
                    </a>
                  </FooterImage>
                </FooterLogoItem>
              </FooterLogos>
            </GridItem>
            <GridItem
              tabletL={1}
              tabletLStart={12}
              tabletLOrder={4}
              desk={1}
              deskStart={12}
              deskOrder={5}
              deskL={1}
              deskLStart={12}
              deskLOrder={6}
            >
              <FooterToTop>
                <ToTopButton
                  onClick={() => gsap.to(window, { scrollTo: 0, duration: 2 })}
                  aria-label="Back to top"
                >
                  <ArrowWrap>
                    <Arrow />
                  </ArrowWrap>
                  <ArrowWrap>
                    <Arrow />
                  </ArrowWrap>
                </ToTopButton>
              </FooterToTop>
            </GridItem>
            <GridItem tabletL={12} tabletLOrder={6} desk={12} deskOrder={7}>
              <FooterCopyright>
                <Copyright>
                  &copy; Copyright {new Date().getFullYear()} Grossman Company
                  Properties
                </Copyright>
                <Copyright>
                  Made by{' '}
                  <a
                    href="https://toyfight.co/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ToyFight®
                  </a>
                </Copyright>
              </FooterCopyright>
            </GridItem>
          </Grid>
        </FooterMain>
        <Spacer size={[60, 80]} />
      </Container>
    </FooterContainer>
  )
}

export default Footer
