import React, { useState, useEffect, useRef, useCallback } from 'react'
import { scrollToId } from '../../utils/utils'
import { useInView } from 'react-intersection-observer'
import { updateHeader, openContact, useStore } from '../../Store'

// Components
import AnimatedArrow from '../AnimatedArrow'
import AnimateSlideIn from '../animation/AnimateSlideIn'
import Button from '../Button'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Spacer from '../Spacer'

// SVG
import Close from '../svgs/Close'
import IconArrowheadRight from '../svgs/IconArrowheadRight'

//Styles
import {
  FooterFormContainer,
  FooterFormMain,
  FooterFormClosed,
  Form,
  FormInput,
  FormLabel,
  FormTextArea,
  InputGroup,
  FormWrap,
  CloseWrap,
  CloseButton,
  FooterFormClosedIcon,
} from './index.style'
import { Heading3, Copyright, TextBodySmaller } from '../TextStyles'
import { colors } from '../../styles/vars/colors.style'
import { animation } from '../../styles/vars/animation.style'

/**
 * Footer Form Component
 * Opens/Closes based on state
 * Form submissions are collected in Netlify and sent to an email address
 */

const FooterForm = () => {
  const [formOpen, setFormOpen] = useState(false)
  const [formSent, setFormSent] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [formHeading, setFormHeading] = useState('Connect with us')
  const formRef = useRef()
  const formWrapRef = useRef(null)
  const formMainRef = useRef(null)
  const formClosedRef = useRef(null)
  const formIsOpen = useRef(formOpen)
  const subjectPrefix = 'Contact form submission from:'
  const firstNameName = 'First name'
  const lastNameName = 'Last name'
  const emailName = 'Email'
  const messageName = 'Message'

  const updateHeight = useCallback(() => {
    const formEl = formMainRef.current
    const formWrapEl = formWrapRef.current
    const formClosedEl = formClosedRef.current
    // const isOpen = formIsOpen.current
    if (formOpen === true) {
      formEl.style.height = formWrapEl.scrollHeight + `px`
      formEl.style.maxHeight = formWrapEl.scrollHeight + `px`
    } else {
      formEl.style.maxHeight = formClosedEl.scrollHeight + `px`
    }
  }, [formOpen])

  const [store, dispatch] = useStore()
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: animation.blockThreshold,
  })

  useEffect(() => {
    formIsOpen.current = formOpen
    updateHeight()
  }, [formOpen, updateHeight])

  useEffect(() => {
    updateHeight()

    window.addEventListener('resize', updateHeight)

    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [updateHeight])

  const toggleForm = () => {
    setFormOpen(prevState => !prevState)
  }

  const closeForm = () => {
    toggleForm()
    scrollToId('contact')
  }

  const encode = data => {
    const value = Object.keys(data).map(
      key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
    )
    return value.join('&')
  }

  const handleSubmit = e => {
    e.preventDefault()

    const data = {
      'form-name': formRef.current.getAttribute('name'),
      subject: `${subjectPrefix} ${firstName} ${lastName}`,
      [firstNameName]: firstName,
      [lastNameName]: lastName,
      [emailName]: email,
      [messageName]: message,
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(data),
    })
      .then(() => {
        setFormSent(true)
        setFormHeading('Form submitted successfully')
        setFormOpen(false)
      })
      .catch(error => alert(error))
  }

  useEffect(() => {
    if (inView === true) {
      updateHeader(dispatch, `contact`)
    }
  }, [inView, dispatch])

  useEffect(() => {
    if (store.contactOpen === true) {
      setFormOpen(true)
    }
  }, [store.contactOpen])

  return (
    <FooterFormContainer id="contact" ref={ref}>
      <FooterFormMain ref={formMainRef}>
        <FooterFormClosed
          ref={formClosedRef}
          formOpen={formOpen}
          formSent={formSent}
          onClick={
            !formSent
              ? () => {
                  toggleForm()
                  openContact(dispatch, false)
                }
              : null
          }
        >
          <Container>
            <Spacer size={[40, 80]} />

            <Heading3>
              <AnimateSlideIn>
                {formHeading}
                {!formSent && (
                  <FooterFormClosedIcon>
                    <AnimatedArrow big />
                  </FooterFormClosedIcon>
                )}
              </AnimateSlideIn>
            </Heading3>

            <Spacer size={[40, 80]} />
          </Container>
        </FooterFormClosed>

        <FormWrap formOpen={formOpen} ref={formWrapRef}>
          <Container>
            <Spacer size={[40, 120]} />
            <Grid>
              <GridItem
                tiny={10}
                tinyStart={2}
                tinyOrder={2}
                desk={6}
                deskStart={4}
                deskOrder={1}
              >
                <Form
                  ref={formRef}
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  name="contact-form"
                  method="POST"
                  onSubmit={handleSubmit}
                >
                  <input type="hidden" name="form-name" value="contact-form" />
                  <input type="hidden" name="bot-field" />
                  <input
                    type="hidden"
                    name="subject"
                    value={`${subjectPrefix} ${firstName} ${lastName}`}
                  />

                  <InputGroup area="firstName">
                    <FormLabel htmlFor={firstNameName}>
                      <Copyright>First Name *</Copyright>
                    </FormLabel>

                    <FormInput
                      type="text"
                      id={firstNameName}
                      name={firstNameName}
                      placeholder="First name"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                      required
                    />
                  </InputGroup>

                  <InputGroup area="lastName">
                    <FormLabel htmlFor={lastNameName}>
                      <Copyright>Last Name *</Copyright>
                    </FormLabel>

                    <FormInput
                      type="text"
                      id={lastNameName}
                      name={lastNameName}
                      placeholder="Last name"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                      required
                    />
                  </InputGroup>

                  <InputGroup area="email">
                    <FormLabel htmlFor={emailName}>
                      <Copyright>Email Address *</Copyright>
                    </FormLabel>

                    <FormInput
                      type="email"
                      id={emailName}
                      name={emailName}
                      placeholder="Your email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      required
                    />
                  </InputGroup>

                  <InputGroup area="message">
                    <FormLabel htmlFor={messageName}>
                      <Copyright>Message *</Copyright>
                    </FormLabel>

                    <FormTextArea
                      rows={6}
                      id={messageName}
                      name={messageName}
                      placeholder="Type your message here..."
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      required
                    />
                  </InputGroup>

                  <InputGroup area="submit">
                    <Button
                      iconRight={<IconArrowheadRight responsive={false} />}
                      color={colors.black}
                    >
                      <TextBodySmaller>Send</TextBodySmaller>
                    </Button>
                  </InputGroup>
                </Form>
              </GridItem>

              <GridItem
                tiny={12}
                tinyOrder={1}
                tabletL={2}
                tabletLStart={10}
                tabletLOrder={1}
                desk={2}
                deskStart={10}
                deskOrder={2}
              >
                <CloseWrap>
                  <CloseButton onClick={closeForm} aria-label={`Close form`}>
                    <Close />
                  </CloseButton>
                </CloseWrap>
              </GridItem>
            </Grid>

            <Spacer size={[20, 80]} />
          </Container>
        </FormWrap>
      </FooterFormMain>
    </FooterFormContainer>
  )
}

export default FooterForm
