import styled from 'styled-components'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'

export const Arrow = styled.span`
  cursor: pointer;
  display: inline-block;
  height: ${({ big }) => (big ? `1.2rem` : `1rem`)};
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ big }) => (big ? `1.5rem` : `1.2rem`)};
  overflow: hidden;
  transform: ${({ prev }) => (prev ? ` rotate(180deg)` : ``)};

  svg {
    height: ${({ big }) => (big ? `1.2rem` : `1rem`)};
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ big }) => (big ? `1.5rem` : `1.2rem`)};
    transition: transform 0.5s ${easings.inOut.default};
  }

  &:first-of-type {
    svg {
      transform: translateX(0);
      transition-delay: 0.2s;
    }
  }
  &:last-of-type {
    svg {
      transform: translateX(0) translateX(-100%);
    }
  }
`

export const ArrowWrap = styled.span`
  display: inline-block;
  overflow: hidden;
  height: ${({ big }) => (big ? `1.2rem` : `1rem`)};
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${({ big }) => (big ? `1.5rem` : `1.2rem`)};
  transform: translateX(-50%) translateY(-50%);

  ${mq.desk} {
    button:hover & {
      ${Arrow} {
        &:first-of-type {
          svg {
            transform: translateX(0) translateY(0) translateX(100%);
          }
        }
        &:last-of-type {
          svg {
            transform: translateX(0) translateY(0);
            transition-delay: 0.2s;
          }
        }
      }
    }
  }
`
