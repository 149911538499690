export const font = {
  primary: {
    family: 'Founders Grotesk',
    weight: {
      light: 300,
      regular: 400,
    },
  },
  secondary: {
    family: 'Suisse Works',
    weight: {
      regular: 400,
      bold: 700,
    },
  },
  fallback: {
    family: 'sans-serif',
  },
}
