import React from 'react'
import {
  Super,
  Heading1,
  Heading2,
  Heading3,
  Label,
  Copyright,
  TextBody,
  TextBodySmall,
  TextBodySmaller,
  TextBodySmallest,
} from '../../styles/vars/textStyles.style'

const ThisSuper = ({ children, as, color, maxWidth, ...props }) => (
  <Super as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Super>
)

const ThisHeading1 = ({ children, as, color, maxWidth, ...props }) => (
  <Heading1 as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Heading1>
)

const ThisHeading2 = ({ children, as, color, maxWidth, ...props }) => (
  <Heading2 as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Heading2>
)

const ThisHeading3 = ({ children, as, color, maxWidth, ...props }) => (
  <Heading3 as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Heading3>
)

const ThisLabel = ({ children, as, color, maxWidth, ...props }) => (
  <Label as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Label>
)

const ThisCopyright = ({ children, as, color, maxWidth, ...props }) => (
  <Copyright as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Copyright>
)

const ThisTextBody = ({ children, as, color, maxWidth, ...props }) => (
  <TextBody as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </TextBody>
)

const ThisTextBodySmall = ({ children, as, color, maxWidth, ...props }) => (
  <TextBodySmall as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </TextBodySmall>
)

const ThisTextBodySmaller = ({ children, as, color, maxWidth, ...props }) => (
  <TextBodySmaller as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </TextBodySmaller>
)
const ThisTextBodySmallest = ({ children, as, color, maxWidth, ...props }) => (
  <TextBodySmallest as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </TextBodySmallest>
)

export {
  ThisSuper as Super,
  ThisHeading1 as Heading1,
  ThisHeading2 as Heading2,
  ThisHeading3 as Heading3,
  ThisLabel as Label,
  ThisTextBody as TextBody,
  ThisTextBodySmall as TextBodySmall,
  ThisTextBodySmaller as TextBodySmaller,
  ThisTextBodySmallest as TextBodySmallest,
  ThisCopyright as Copyright,
}
