import gsap from 'gsap'
import React from 'react'

export function disablePageScroll() {
  document.body.classList.add('disable-scroll')
}

export function enablePageScroll() {
  document.body.classList.remove('disable-scroll')
}

export function focusElement(el) {
  el.setAttribute('tabindex', 0)
  el.focus()
}

export function forceTrailingSlash(string) {
  return string[string.length - 1] === '/' ? string : string + '/'
}

export function scrollToId(id, dispatch, location) {
  let el = document.querySelector(`#${id}`)

  if (!el) return

  const distance = el.getBoundingClientRect().top
  const adjustedDuration = Math.min(Math.abs(distance / 1500), 3)

  gsap.to(window, {
    scrollTo: el,
    duration: adjustedDuration,
    ease: 'power1.inOut',
  })

  if (location) {
    const cleanURL = forceTrailingSlash(
      location.href.replace(location.search, '')
    )
    window.history.replaceState({}, '', cleanURL)
  }
}

export function formatMarkdown(text) {
  return React.Children.toArray(
    text.split('\n').map((text, i) => [i > 0 && <br />, text])
  )
}
