import styled, { css } from 'styled-components'
import { font } from '../../styles/vars/font.style'
import { colors } from '../../styles/vars/colors.style'
import { clamp } from '../../styles/utils/conversion.style'
import { easings } from '../../styles/vars/easings.style'

export const AnimateIcon = styled.div`
  overflow: hidden;
  height: 1rem;
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
  width: 1.2rem;

  svg {
    transition: transform 0.5s ${easings.inOut.default};
  }

  &:first-of-type {
    svg {
      transform: translateX(0%);
      transition-delay: 0.2s;
    }
  }
  &:last-of-type {
    svg {
      transform: translateX(0%) translateY(0%) translateX(-100%);
    }
  }
`

export const ButtonMain = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 4.4rem;
  min-height: 4.4rem;
  padding: ${21 / 21}em ${35 / 21}em;
  cursor: pointer;
  font-family: ${font.primary.family};
  ${clamp('font-size', 16, 21)}
  line-height: 1.35;
  letter-spacing: 0.02em;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 7em;

  ${clamp('width', 160, 283)}

  &[disabled] {
    opacity: 0.5;
    cursor: default;
    color: ${colors.light};
    background-color: ${colors.dark};
    border-color: ${colors.grey};
  }

  ${({ variant }) => {
    if (variant === 'primary') {
      return ButtonPrimary
    }

    if (variant === 'secondary') {
      return ButtonSecondary
    }
  }}

  &:hover {
    ${AnimateIcon} {
      &:first-of-type {
        svg {
          transform: translateX(100%);
        }
      }
      &:last-of-type {
        svg {
          transform: translateX(0%);
          transition-delay: 0.2s;
        }
      }
    }
  }
`

const ButtonPrimary = css`
  color: ${colors.black};
  background-color: ${colors.white};
  transition-property: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ${easings.inOut.default};

  :not(:disabled):hover {
    /* color: ${colors.blue};
    background-color: ${colors.light}; */
  }
`

const ButtonSecondary = css`
  color: ${colors.blue};
  background-color: transparent;
  transition-property: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ${easings.inOut.default};

  :not(:disabled):hover {
    color: ${colors.light};
    background-color: ${colors.blue};
  }
`

export const ButtonContent = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const ButtonIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: ${props => (props.left ? 'flex-start' : 'flex-end')};
  width: 1em;
  margin-left: ${props => (props.right ? '0.5em' : undefined)};
  margin-right: ${props => (props.left ? '0.5em' : undefined)};
`

export const ButtonText = styled.span``
