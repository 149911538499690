import React, { useState, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useStaticQuery, graphql } from 'gatsby'
import { updateHeader, openContact, useStore } from '../../Store'

// Components
import Container from '../Container'

// Styles
import {
  HeaderWrap,
  HeaderContainer,
  HeaderLogo,
  HeaderLogoMainMob,
  HeaderMain,
  HeaderMenu,
  HeaderMenuItem,
  HeaderNav,
  HeaderLinkItem,
} from './index.style'
import { colors } from '../../styles/vars/colors.style'
import { TextBodySmaller } from '../../styles/vars/textStyles.style'

// SVGs
import FullLogo from '../svgs/FullLogo'
import LogoDesk from '../svgs/Logo'
import LogoMobile from '../svgs/LogoMobile'

// Functions
import { scrollToId } from '../../utils/utils'

/**
 * Header
 *
 * Anchor links to specified sections on the page
 * Anchor links are not visible if user is on any page other than the home page
 * Anchor links are not visible on mobile devices
 *
 * When page scrolls more than 100px from the top, logo changes to a smaller version and the nav becomes sticky
 * - Resets when user goes back to the top of the page
 */

const Header = ({ pathname }) => {
  const [store, dispatch] = useStore()

  const [inViewRef, inView] = useInView({ triggerOnce: true })

  const [isScrolled, setIsScrolled] = useState(false),
    [isAnimatedIn, setIsAnimatedIn] = useState(false),
    [scrollValue, setScrollValue] = useState(0),
    [scrollDirection, setScrollDirection] = useState(`down`),
    [activeHeaderItem, setActiveHeaderItem] = useState(store.activeHeaderItem)

  const timeoutRef = useRef(null)

  const data = useStaticQuery(graphql`
    query {
      contentfulGlobalSettings {
        headerMenu {
          ... on ContentfulBlockText {
            contentfulid
            titleCmsOnly
          }
          ... on ContentfulBlockTextAndMedia {
            anchorIdOptional
            blockTitle
          }
          ... on ContentfulBlockAcquisition {
            id
            anchorIdOptional
            blockTitle
          }
          ... on ContentfulBlockCarousel {
            id
            title
            anchorIdOptional
          }
        }
      }
    }
  `)

  useEffect(() => {
    const checkScroll = e => {
      const y = window.scrollY
      if (y >= 100) {
        setIsScrolled(true)
      } else if (y === 0) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        updateHeader(dispatch, 'intro')
        setIsAnimatedIn(false)
        setIsScrolled(false)
      }

      if (scrollValue > y) {
        setScrollDirection('up')
      } else if (scrollValue < y) {
        setScrollDirection('down')
      }
      setScrollValue(y)
    }

    window.addEventListener('scroll', checkScroll)

    return () => {
      window.removeEventListener('scroll', checkScroll)
    }
  }, [scrollValue, dispatch])

  useEffect(() => {
    if (isScrolled === true) {
      timeoutRef.current = setTimeout(() => {
        setIsAnimatedIn(true)
      }, 100)
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [isScrolled])

  const clickToSetActiveItem = (e, key) => {
    if (store.activeHeaderItem !== key) {
      updateHeader(dispatch, key)
    }
  }

  useEffect(() => {
    setActiveHeaderItem(store.activeHeaderItem)
  }, [activeHeaderItem, store.activeHeaderItem])

  return (
    <HeaderWrap scrolled={isScrolled} ref={inViewRef} inView={inView}>
      <HeaderContainer scrolled={isScrolled} animateIn={isAnimatedIn}>
        <Container>
          <HeaderMain>
            <HeaderLogo href="/" scrolled={isScrolled}>
              <LogoDesk />
              <LogoMobile fill={colors.green} />
            </HeaderLogo>
            <HeaderLogoMainMob>
              <FullLogo fill={colors.green} />
            </HeaderLogoMainMob>

            <HeaderNav hide={pathname !== '/' ? true : false}>
              <HeaderMenu>
                {data.contentfulGlobalSettings.headerMenu.map((item, key) => {
                  return item.contentfulid || item.anchorIdOptional ? (
                    <HeaderMenuItem
                      key={key}
                      active={
                        activeHeaderItem === item.contentfulid
                          ? true
                          : activeHeaderItem === item.anchorIdOptional
                          ? true
                          : false
                      }
                      scrollDirection={scrollDirection}
                    >
                      <HeaderLinkItem
                        href={`#${
                          item.contentfulid
                            ? item.contentfulid
                            : item.anchorIdOptional
                        }`}
                        onClick={e => {
                          e.preventDefault()
                          clickToSetActiveItem(
                            e,
                            item.contentfulid
                              ? item.contentfulid
                              : item.anchorIdOptional
                          )
                          scrollToId(
                            item.contentfulid
                              ? item.contentfulid
                              : item.anchorIdOptional
                          )
                        }}
                        tabIndex="-1"
                      >
                        <TextBodySmaller>
                          {item.titleCmsOnly
                            ? item.titleCmsOnly
                            : item.blockTitle
                            ? item.blockTitle
                            : item.title}
                        </TextBodySmaller>
                      </HeaderLinkItem>
                    </HeaderMenuItem>
                  ) : (
                    ``
                  )
                })}
                <HeaderMenuItem
                  active={activeHeaderItem === `contact` ? true : false}
                  scrollDirection={scrollDirection}
                >
                  <HeaderLinkItem
                    href={`#contact`}
                    onClick={e => {
                      e.preventDefault()
                      clickToSetActiveItem(e, `contact`)
                      openContact(dispatch, true)
                      scrollToId(`contact`)
                    }}
                    tabIndex="-1"
                  >
                    <TextBodySmaller>Contact</TextBodySmaller>
                  </HeaderLinkItem>
                </HeaderMenuItem>
              </HeaderMenu>
            </HeaderNav>
          </HeaderMain>
        </Container>
      </HeaderContainer>
    </HeaderWrap>
  )
}

export default Header
