import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const Close = ({
  width = 12,
  height = 12,
  fill = colors.cream,
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      aria-labelledby="Close"
      focusable="false"
      role="img"
      fill={`none`}
      {...svgAttributes}
    >
      <title id="Close">Close</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.37705 0.62295L0.528522 1.47148L5.05428 5.99724L0.525759 10.5258L1.37429 11.3743L5.90281 6.84577L10.428 11.371L11.2765 10.5224L6.75134 5.99724L11.2738 1.47479L10.4253 0.626265L5.90281 5.14871L1.37705 0.62295Z"
        fill={fill}
      />
    </svg>
  )
}

export default Close
