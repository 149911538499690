import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { AnimateSlideInContent, AnimateSlideInMask } from './index.style'

const AnimateSlideIn = ({
  direction = 'bottom',
  duration = 0.8,
  delay = 0,
  children,
}) => {
  const [ref, inView] = useInView({ triggerOnce: true })

  return (
    <AnimateSlideInMask ref={ref}>
      <AnimateSlideInContent
        inView={inView}
        direction={direction}
        duration={duration}
        delay={delay}
      >
        {children}
      </AnimateSlideInContent>
    </AnimateSlideInMask>
  )
}

AnimateSlideIn.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  duration: PropTypes.number,
  direction: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
}

export default AnimateSlideIn
