import { css } from 'styled-components'
import { font } from '../vars/font.style'
import { colors } from '../vars/colors.style'
import { transparentize } from 'polished'

export const typography = css`
  html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  body {
    font-size: 1.8rem;
    font-family: ${font.primary.family}, ${font.fallback.family};
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: ${colors.black};
  }

  strong {
    font-weight: ${font.primary.weight.bold};
  }

  /* Links */
  a {
    color: currentColor;
  }

  /* Headings - * 0.625 for vw @ 1600 */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${font.secondary.family};
    font-style: normal;
    font-weight: ${font.secondary.weight.regular};
  }

  blockquote {
    padding-left: 0.5em;
    border-left: 0.4rem solid ${transparentize(0.5, colors.dark)};

    .split__text:before,
    .split__text:after {
      content: '"';
      display: inline;
    }
  }

  /* Forms */
  input,
  button,
  textarea {
    font-family: inherit;
    font-size: inherit;
  }

  input,
  textarea {
    ::placeholder {
      font-size: inherit;
      font-family: inherit;
      font-style: inherit;
      font-weight: inherit;
      line-height: inherit;
    }
  }

  /* Selection */
  ::-moz-selection {
    background: ${colors.dark};
    color: ${colors.light};
    text-shadow: none;
  }

  ::selection {
    background: ${colors.dark};
    color: ${colors.light};
    text-shadow: none;
  }

  /* Focus */

  *:focus {
    outline: none;

    .keyboard-in-use & {
      outline: ${transparentize(0.5, colors.dark)} auto 0.4rem;
      outline-style: solid;
    }
  }
`
