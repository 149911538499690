import styled from 'styled-components'
import { font } from '../vars/font.style'
import { clamp } from '../utils/conversion.style'
import { mq } from './media-queries.style'

export const textStylesConfig = {
  super: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 96,
      max: 220,
    },
    lineHeight: 1,
    letterSpacing: -0.03,
  },
  heading1: {
    family: font.secondary.family,
    weight: font.secondary.weight.regular,
    size: {
      min: 45,
      max: 80,
      desk: 105,
    },
    lineHeight: 1.26,
    letterSpacing: -0.03,
  },
  heading2: {
    family: font.secondary.family,
    weight: font.secondary.weight.regular,
    size: {
      min: 30,
      max: 56,
    },
    lineHeight: 1.2,
    letterSpacing: -0.02,
  },
  heading3: {
    family: font.secondary.family,
    weight: font.secondary.weight.regular,
    size: {
      min: 22,
      max: 40,
    },
    lineHeight: 1.2,
  },
  body: {
    family: font.primary.family,
    weight: font.primary.weight.light,
    size: {
      min: 19,
      max: 32,
    },
    lineHeight: 1.3,
    letterSpacing: 0.02,
  },
  bodySmall: {
    family: font.primary.family,
    weight: font.primary.weight.light,
    size: {
      min: 17,
      max: 28,
    },
    lineHeight: 1.35,
    letterSpacing: 0.02,
  },
  bodySmaller: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 14,
      max: 21,
    },
    lineHeight: 1.35,
    letterSpacing: 0.02,
  },
  bodySmallest: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 14,
      max: 19,
    },
    lineHeight: 1.35,
    letterSpacing: 0.02,
  },
  label: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 15,
      max: 24,
    },
    lineHeight: 1.1,
    letterSpacing: 0.05,
  },
  copyright: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 13,
      max: 16,
    },
    lineHeight: 1.35,
    letterSpacing: 0.02,
  },
}

export const getStyles = style => `
${clamp('font-size', style.size.min, style.size.max)}
  font-family: ${style.family};
  font-weight: ${style.weight};
  line-height: ${style.lineHeight};
  ${style.letterSpacing ? `letter-spacing: ${style.letterSpacing}em` : ''};
  ${style.uppercase ? 'text-transform: uppercase' : ''};
`

export const Super = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getStyles(textStylesConfig.super)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading1 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getStyles(textStylesConfig.heading1)}
  color: ${props => (props.color ? props.color : undefined)};

  ${mq.deskL} {
    ${clamp(
      'font-size',
      textStylesConfig.heading1.max,
      textStylesConfig.heading1.desk
    )}
  }
`

export const Heading2 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getStyles(textStylesConfig.heading2)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading3 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getStyles(textStylesConfig.heading3)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Label = styled.p`
  display: inline-block;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  padding-bottom: 0.2em;
  ${getStyles(textStylesConfig.label)}
  color: ${props => (props.color ? props.color : undefined)};
  text-transform: uppercase;
  border-bottom: 0.1rem solid;
`
export const Copyright = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getStyles(textStylesConfig.copyright)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBody = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getStyles(textStylesConfig.body)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBodySmall = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getStyles(textStylesConfig.bodySmall)}
  color: ${props => (props.color ? props.color : undefined)};
`
export const TextBodySmaller = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getStyles(textStylesConfig.bodySmaller)}
  color: ${props => (props.color ? props.color : undefined)};
`
export const TextBodySmallest = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getStyles(textStylesConfig.bodySmallest)}
  color: ${props => (props.color ? props.color : undefined)};
`
