import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const Arrow = ({
  width = 15,
  height = 18,
  fill = colors.cream,
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 18"
      focusable="false"
      role="img"
      fill={`none`}
      {...svgAttributes}
    >
      <path
        d="M7.5 18L-4.60108e-07 10.526L1.28641 9.32443L6.60194 14.6595L6.60194 -3.67091e-07L8.4466 -2.86458e-07L8.4466 14.6115L13.6893 9.32443L15 10.526L7.5 18Z"
        fill={fill}
      />
    </svg>
  )
}

export default Arrow
