import React from 'react'

// Vars
import { colors } from '../../styles/vars/colors.style'

// Icons
import IconArrowheadRight from '../svgs/IconArrowheadRight'

// Styles
import { ArrowWrap, Arrow } from './index.style'

const AnimatedArrow = ({ prev, color = colors.cream, big }) => {
  return (
    <ArrowWrap big={big}>
      <Arrow prev={prev} big={big}>
        <IconArrowheadRight fill={color} big={big} />
      </Arrow>
      <Arrow prev={prev} big={big}>
        <IconArrowheadRight fill={color} big={big} />
      </Arrow>
    </ArrowWrap>
  )
}

export default AnimatedArrow
