import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const IconArrowheadRight = ({
  width = 12,
  height = 10,
  fill = colors.black,
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 10"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M12 5L7.01736 10L6.21629 9.1424L9.77303 5.59871L0 5.59871L0 4.36893L9.74099 4.36893L6.21629 0.873787L7.01736 0L12 5Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconArrowheadRight
