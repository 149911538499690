import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { clamp } from '../../styles/utils/conversion.style'
import { transparentize } from 'polished'
import { easings } from '../../styles/vars/easings.style'

export const FooterFormContainer = styled.div`
  background-color: ${colors.darkGreen};
  color: ${colors.cream};
  overflow: hidden;
  position: relative;
`

export const FooterFormMain = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  height: 100%;
  transition: max-height 0.5s ${easings.inOut.default};
`

export const FooterFormClosed = styled.button`
  width: 100%;
  cursor: ${props => (props.formSent ? 'default' : 'pointer')};
  text-align: center;
  color: currentColor;
  background-color: transparent;
  border: none;

  transition: opacity 0.5s ${easings.inOut.default};
  ${({ formOpen }) => {
    return formOpen === true
      ? `opacity: 0;`
      : `opacity: 1;transition-delay: .5s;`
  }}
`

export const FooterFormClosedIcon = styled.span`
  display: inline-block;
  height: 2.2rem;
  width: 1.5rem;
  margin-left: 0.6em;
  transform: rotate(90deg);
`

export const FormWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: ${props => (props.formOpen ? undefined : 'none')};
  ${({ formOpen }) => {
    return formOpen === false
      ? `opacity: 0; pointer-events: none;`
      : `opacity: 1; transition-delay: .5s;`
  }}
`

export const CloseWrap = styled.div`
  text-align: right;
`
export const CloseButton = styled.button`
  background: transparent;
  border: 0.1rem solid ${transparentize(0.7, colors.light)};
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  ${clamp('height', 50, 70)}
  ${clamp('width', 50, 70)}

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`

export const Form = styled.form`
  display: grid;
  ${clamp('grid-column-gap', 8, 20)}
  grid-template-columns: repeat(6, 1fr);
  align-items: flex-start;
  grid-template-areas:
    'firstName firstName firstName firstName firstName firstName'
    'lastName lastName lastName lastName lastName lastName'
    'email email email email email email'
    'message message message message message message'
    'submit submit submit submit submit submit';

  ${mq.tabletP} {
    grid-template-areas:
      'firstName firstName firstName lastName lastName lastName'
      'email email email email email email'
      'message message message message message message'
      'submit submit submit submit submit submit';
  }
`

export const InputGroup = styled.div`
  position: relative;
  width: 100%;
  grid-area: ${props => props.area};

  button {
    background-color: ${colors.cream};
  }

  :not(:last-child) {
    margin-bottom: 6rem;
  }
`

export const FormLabel = styled.label`
  display: block;
  margin-bottom: 0.54em;
  font-size: 1.6rem;
  line-height: 1.35;
  letter-spacing: 0.07em;
  text-transform: uppercase;
`

export const FormInput = styled.input`
  width: 100%;
  padding-bottom: 0.8em;
  font-size: 1.9rem;
  line-height: 1.35;
  letter-spacing: 0.02em;
  color: ${colors.cream};
  background-color: transparent;
  border: none;
  border-bottom: 0.1rem solid ${colors.cream};
  border-radius: 0;

  /* :focus {
    outline: none;
  } */

  ::placeholder {
    color: currentColor;
  }
`
export const FormTextArea = styled.textarea`
  display: block;
  width: 100%;
  font-size: 1.9rem;
  line-height: 1.35;
  letter-spacing: 0.02em;
  color: ${colors.cream};
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 0.1rem solid ${colors.cream};

  /* :focus {
    outline: none;
  } */

  ::placeholder {
    color: currentColor;
  }
`

export const FormSubmitMessage = styled.div`
  visibility: hidden;
  margin-top: 2rem;
`
