export const colors = {
  dark: '#000',
  light: '#fff',
  black: '#1C1C1C',
  cream: '#E9E8E0',
  green: '#46724B',
  lightGreen: '#C0CABB',
  darkGreen: '#3F6743',
  darkBrown: '#313130',
  gold: '#7D725A',
}
